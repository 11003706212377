<template>
  <div class="row justify-content-center">
    <div class="col-auto">
      <table class="table-primary table-responsive">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Date</th>
          <th scope="col">File Name</th>
          <th scope="col">Link</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(ele,key) in dataResult" :key="ele.id">
          <th scope="row">{{ (key+1) }}</th>
          <td>{{ ele.shootingDate }}</td>
          <td>{{ ele.fileName }}</td>
          <td>
            <base-button @click="downloadPhoto(ele.url)">Download</base-button>
            <debug-div-shower>
              <base-button @click="deletePhoto(ele.userName, ele.id)">Delete</base-button>
            </debug-div-shower>
          </td>
        </tr>
        </tbody>
      </table>
      <pre>{{ infoResult }}</pre>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton.vue";
import DebugDivShower from "@/components/Debugger/DebugDivShower.vue";

export default {
  components: {BaseButton, DebugDivShower},
  props: ['dataResult'],
  data() {
    return {
      infoResult: ''
    }
  },
  methods:{
    downloadPhoto(url){
      window.open(url,'_blank');
    },
    deletePhoto(userName, id){
      if (confirm("Do you really want to delete?")) {
        fetch('https://vue-photography-default-rtdb.firebaseio.com/'+userName+'/' + id + '.json', {
          method: 'DELETE',
        }).then(res => {
          if (!res.ok) {
            this.infoResult = res;
          } else {
            this.infoResult = 'Successfully Deleted! Reload to refresh the result';
          }
        }).catch((e) => {
          console.log(e);
        })
      }
    }
  }
}
</script>

<style scoped>
table {
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}
table, td, th {
  border: 1px solid #ccc;
  padding: 8px;
}

td, th {
  vertical-align: middle;
}
</style>
