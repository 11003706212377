<template>
  <div class="container col-4">
    <div class="row">
      <lable class="col-4"> User Name:</lable>
      <input class="col-8" type="text" v-model="userName">
    </div>
    <div class="row">
      <lable class="col-4"> Shooting Date:</lable>
      <input class="col-8" type="text" v-model.trim="shootingDate">
    </div>
    <div class="row">
      <lable class="col-4"> File Name:</lable>
      <input class="col-8" type="text" v-model.trim="fileName" @blur="generateURL">
    </div>
    <div class="row">
      <lable class="col-4"> URL:</lable>
      <input class="col-8" type="text" v-model.trim="url">
    </div>

    <base-button @click="btnAddingPhotoList">
      Adding Photo List
    </base-button>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton.vue";

export default {
  components: {
    BaseButton
  },
  data() {
    return {
      userName: '',
      shootingDate: '',
      fileName: '',
      url: '',
    };
  },
  methods: {
    btnAddingPhotoList() {
      const that = this;
      if (this.userName != '' && this.shootingDate != '' && this.fileName != '' && this.url != '') {
        fetch('https://vue-photography-default-rtdb.firebaseio.com/' + this.userName + '.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userName: that.userName,
            shootingDate: that.shootingDate,
            fileName: that.fileName,
            url: that.url,
          }),
        }).then(res => {
          if (!res.ok) {
            console.log(res);
          }
        }).catch((e) => {
          console.log(e);
        })
        this.init();
      }
    },
    init(){
      this.userName = localStorage.getItem('userName');
      this.shootingDate = new Date().toISOString();
      this.fileName = '';
      this.url = '';
    },
    generateURL(){
      this.url = 'https://photographydays.s3.ap-northeast-1.amazonaws.com/' + this.fileName + '.zip';
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
.row {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
