<template>
  <h4>
    Welcome to Photography Days
  </h4>
</template>

<style scoped>
h4 {
  margin: 2rem auto;
  max-width: 30rem;
  padding: 1rem;
}
</style>