<template>
  <div v-if='debugInit'>
    <slot></slot>
  </div>
  <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
</template>

<script>
import KeyboardEvents from "@/components/Debugger/KeyboardEvents.vue";
export default {
  components: {KeyboardEvents},
  data(){
    return{
      debugInit: false,
      loggerCode : ['d','e','b','u','g','Enter'],
      counter : 0,
    };
  },
  methods:{
    keyboardEvent(e){
      if(e.key === this.loggerCode[this.counter]){
        this.counter ++;
        if(this.counter == this.loggerCode.length){
          this.counter = 0;
          this.debugInit = true;
          localStorage.setItem('debug','true');
        }
      }else{
        this.counter = 0;
      }
    }
  },mounted() {
    localStorage.setItem('debug','false');
  }
}

</script>
