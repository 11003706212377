<template>
  <the-header></the-header>
  <div class="container col-10 col-sm-8 col-md-7 col-lg-6 col-xl-4">
    <div class="row my-2">
      <lable class="col-6 col-md-5 col-xl-4"> User Name:</lable>
      <input class="col-6 col-md-7 col-xl-8" type="text" v-model.trim="userName">
    </div>
    <div class="row my-2">
      <lable class="col-6 col-md-5 col-xl-4"> User Code:</lable>
      <input class="col-6 col-md-7 col-xl-8" type="password" v-model.trim="userPassword">
    </div>
  </div>
  <p :hidden="userLoginSuccess" class="text-danger"> Wrong user name or password </p>
  <base-button @click="btnLogin">
    Login
  </base-button>
  <base-button @click="btnClear">
    Clear
  </base-button>
  <debug-div-shower>
<!--  </debug-div-shower>-->
  <hr>
    <div class="container col-10 col-sm-8 col-md-7 col-lg-6 col-xl-4">
      <div class="row my-2">
        <lable class="col-6 col-md-5 col-xl-4"> New Name:</lable>
        <input class="col-6 col-md-7 col-xl-8" type="text" v-model.trim="newUserName" @blur="autoPassCode">
      </div>
      <div class="row my-2">
        <lable class="col-6 col-md-5 col-xl-4"> New Code:</lable>
        <input class="col-6 col-md-7 col-xl-8" type="text" v-model.trim="newUserPassword">
      </div>
    </div>
    <base-button @click="btnAddingData"> Add</base-button>
    <p>{{ resultData }}</p>
    <p>{{ resultDataUser }}</p>
    <p>{{ resultDataPass }}</p>
    <hr>
    <base-button @click="btnGetAllUser"> Get All User</base-button>
    <user-data-table :dataResult= 'theUserListResults'></user-data-table>
    </debug-div-shower>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton.vue";
import TheHeader from "@/components/UI/TheHeader.vue";
import DebugDivShower from "@/components/Debugger/DebugDivShower.vue";
import UserDataTable from "@/components/Tables/UserDataTable.vue";

export default {
  components: {UserDataTable, TheHeader, BaseButton, DebugDivShower},
  data() {
    return {
      userName: '',
      userPassword: '',
      newUserName: '',
      newUserPassword: '',
      userLoginSuccess: true,

      resultData: '',
      resultDataUser: '',
      resultDataPass: '',

      theUserListResults: '',
    };
  }, methods: {
    btnLogin() {
      localStorage.setItem('userName', this.userName);
      localStorage.setItem('userPassword', this.userPassword);
      this.userLoginSuccess = true;
      fetch('https://vue-photography-default-rtdb.firebaseio.com/list.json').then((res) => {
        if (res.ok) {
          return res.json();
        }
      }).then((data) => {
        const results = [];
        for (const id in data) {
          results.push({id: id, name: data[id].name, password: data[id].password});
        }
        this.checkingData(results);
      }).catch((e) => {
        this.userLoginSuccess = false;
        console.log(e);
      });
    },
    btnClear() {
      this.userName = '';
      this.userPassword = '';
      this.newUserName = '';
      this.newUserPassword = '';
      this.userLoginSuccess = true;
    },

    checkingData(results) {
      const found = results.find(ele => ele.name == this.userName);
      if (found.password === this.userPassword) {
        localStorage.setItem('freePass', 'true');
        location.reload();
      } else {
        this.userLoginSuccess = false;
      }
    },

    btnAddingData() {
      const that = this;
      if (this.newUserName === '' || this.newUserPassword === '') {
        return;
      }
      let localNewUserName = this.newUserName;
      let newUserPassword = this.newUserPassword;
      fetch('https://vue-photography-default-rtdb.firebaseio.com/list.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: that.newUserName,
          password: that.newUserPassword,
        }),
      }).then(res => {
        if (!res.ok) {
          this.resultData = res;
        } else {
          this.resultData = `Your User Account has been created`;
          this.resultDataUser = `New User Name: ${localNewUserName}`;
          this.resultDataPass = `New Code: ${newUserPassword}`;
        }
      }).catch((e) => {
        console.log(e);
      });
      this.btnClear();
    },
    autoPassCode(){
      this.newUserPassword = this.randomStringGenerate(6);
    },
    randomStringGenerate(length) {
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    btnGetAllUser(){
      const that = this;
      fetch('https://vue-photography-default-rtdb.firebaseio.com/list.json').then((res) => {
        if (res.ok) {
          return res.json();
        }
      }).then((data) => {
        const results = [];
        for (const id in data) {
          results.push({id: id, name: data[id].name, password: data[id].password});
        }
        that.theUserListResults = results;
      }).catch((e) => {
        console.log(e);
        that.theError = 'failed to fetch data - try again or connect developer';
      });
    }

  },
};

</script>