<template>
  <div class="float-end loggedIn">
    <base-button class="align-right" @click="btnLogOut">
      Log Out
    </base-button>
  </div>
  <the-header></the-header>
  <debug-div-shower>
    <add-new-photo-list></add-new-photo-list>
  </debug-div-shower>
  <p v-if="userNameError">Error Occur</p>

  <photo-data-table
      :dataResult= 'thePhotoListResults'
  ></photo-data-table>

</template>

<script>
import BaseButton from "@/components/UI/BaseButton.vue";
import TheHeader from "@/components/UI/TheHeader.vue";
import AddNewPhotoList from "@/components/Page/AddNewPhotoList.vue";
import DebugDivShower from "@/components/Debugger/DebugDivShower.vue";
import PhotoDataTable from "@/components/Tables/PhotoDataTable.vue";


export default {
  components: {PhotoDataTable, TheHeader, BaseButton, AddNewPhotoList, DebugDivShower},
  data() {
    return {
      userName: '',
      userNameError: false,
      thePhotoListResults: [],
      theError: null
    };
  },
  methods: {
    btnLogOut() {
      localStorage.clear();
      location.reload();
    },

    gettingPhotoList() {
      const that = this;
      fetch('https://vue-photography-default-rtdb.firebaseio.com/' + localStorage.getItem("userName") + '.json').then((res) => {
        if (res.ok) {
          return res.json();
        }
      }).then((data) => {
        const results = [];
        for (const id in data) {
          results.push({id: id, shootingDate: data[id].shootingDate.slice(0, 10), fileName: data[id].fileName, url: data[id].url, userName: data[id].userName});
        }
        that.thePhotoListResults = results;
      }).catch((e) => {
        console.log(e);
        that.theError = 'failed to fetch data - try again or connect developer';
      });
    },
  },
  mounted() {
    if (localStorage.getItem("userName").trim() != '') {
      this.userNameError = false;
      this.userName = localStorage.getItem("userName");
      this.gettingPhotoList();
    } else {
      this.userNameError = true;
    }
  }
}
</script>

<style scoped>
div .loggedIn {
  margin-top: -50px;
  padding-right: 10px;
}
</style>