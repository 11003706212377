<template>
  <div class="row justify-content-center">
    <div class="col-auto">
      <table class="table table-respopnsive">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">FireBase ID</th>
          <th scope="col">User Name</th>
          <th scope="col">User Code</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(ele,key) in dataResult" :key="ele.id">
          <th scope="row">{{ (key + 1) }}</th>
          <td>{{ ele.id }}</td>
          <td>{{ ele.name }}</td>
          <td>{{ ele.password }}</td>
          <td>
            <base-button @click="loginUser(ele.name,ele.password)">Login</base-button>
            <base-button @click="showInfo(ele.name,ele.password)">Show Info</base-button>
            <base-button @click="deleteUser(ele.id)">Delete</base-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <pre>{{ infoResult }}</pre>
</template>

<script>

import BaseButton from "@/components/UI/BaseButton.vue";

export default {
  components: {BaseButton},
  props: ['dataResult'],
  data() {
    return {
      infoResult: ''
    }
  },
  methods: {
    loginUser(eleName, elePassword) {
      localStorage.setItem('userName', eleName);
      localStorage.setItem('userPassword', elePassword);
      // this.userLoginSuccess = true;
      fetch('https://vue-photography-default-rtdb.firebaseio.com/list.json').then((res) => {
        if (res.ok) {
          return res.json();
        }
      }).then((data) => {
        const results = [];
        for (const id in data) {
          results.push({id: id, name: data[id].name, password: data[id].password});
        }
        this.checkingData(results, eleName, elePassword);
      }).catch((e) => {
        // this.userLoginSuccess = false;
        console.log(e);
      });
    },
    deleteUser(userFirebaseId) {
      if (confirm("Do you really want to delete?")) {
        fetch('https://vue-photography-default-rtdb.firebaseio.com/list/' + userFirebaseId + '.json', {
          method: 'DELETE',
        }).then(res => {
          if (!res.ok) {
            this.infoResult = res;
          } else {
            this.infoResult = 'Successfully Deleted! Reload to refresh the result';
          }
        }).catch((e) => {
          console.log(e);
        })
      }
    },
    checkingData(results, eleName, elePassword) {
      const found = results.find(ele => ele.name == eleName);
      if (found.password === elePassword) {
        localStorage.setItem('freePass', 'true');
        console.log("Clipboard Copied");
        location.reload();
      }
    },
    showInfo(eleName,elePassword){
      this.infoResult  = "Dear " + eleName + "\n" +
      "Your Photos are ready!\n" +
      "https://photographydays-login.soemyatmin.it/\n" +
      "User Name: " + eleName + "\n" +
      "User Code: " + elePassword + "\n" +
      "\n" +
      "Thanks For Choosing Photography Days in Japan.\n" +
      "We will happy if you tag our page \"PhotographyDays\" when you upload on Facebook.\n" +
      "+8180 9688 4820 \n";
    }
  }
}
</script>

<style scoped>
table {
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

tr {
}

table, td, th {
  border: 1px solid #ccc;
}

td, th {
  vertical-align: middle;
}

pre {
  white-space: pre-wrap;
}
</style>
