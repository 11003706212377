<template>
  <firebase></firebase>
  <login-init v-if="!authInfo"></login-init>
  <logged-in v-else></logged-in>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import LoginInit from "@/components/MainPage/LoginInit.vue";
import LoggedIn from "@/components/MainPage/LoggedIn.vue";

export default {
  name: 'App',
  components: {LoggedIn, LoginInit},
  data() {
    return {
      authInfo: false
    };
  },
  mounted() {
    if (localStorage.getItem('freePass') === 'true') {
      this.authInfo = true;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
hr{
  margin-bottom: 25px;
  margin-left: 20%;
  margin-right: 20%;
}
</style>
